<template>
  <div class="clothing-item-description"
       :class="$mq">
    <div class="heading-accent">
      {{ clothingItem.title }}
    </div>
    <div class="price">
      {{ clothingItem.price | currency }} {{ clothingItem.currencySymbol }}
    </div>

    <div class="description">
      {{ clothingItem.description }}
    </div>

    <base-button v-if="clothingItem.partner.is_available"
                 @click="onBuyClick"
                 class="buy-btn">
      {{ $t('shared.clothingItem.buy') }}
    </base-button>

    <ul class="meta">
      <li class="characteristic">
        <div class="name">
          {{ $t('studio.catalogue.singleGood.brand') }}
        </div>
        <div class="value">
          {{ clothingItem.brand.title }}
        </div>
      </li>
      <li class="characteristic">
        <div class="name">
          {{ $t('studio.catalogue.singleGood.partner') }}
        </div>
        <div class="value">
          <a v-if="clothingItem.partner.is_available"
             :href="clothingItem.partner.partner_url"
             _target="blank">
            {{ clothingItem.partner.title }}
          </a>
          <template v-else>
            {{ clothingItem.partner.title }}
          </template>
        </div>
      </li>
      <li class="characteristic">
        <div class="name">
          {{ $t('studio.catalogue.singleGood.price') }}
        </div>
        <div class="value">
          {{ clothingItem.price | currency }} {{ clothingItem.currencySymbol }}
        </div>
      </li>
    </ul>

    <clothing-item-actions :clothing-item="clothingItem"
                           :actions="['toCart', 'like']" />
  </div>
</template>

<script>
import ClothingItemActions from '@/components/Shared/ClothingItemActions.vue'

export default {
  props: {
    clothingItem: {
      required: true,
      type: Object
    }
  },

  components: {
    ClothingItemActions
  },

  methods: {
    onBuyClick() {
      window.open(this.clothingItem.partner_side_info.url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .clothing-item-description {
    width: 400px;

    &.mobile {
      width: auto;
    }

    .price {
      margin-bottom: 20px;
    }

    .description {
      font-size: 14px;
      color: #797979;
      line-height: 23px;
      margin-bottom: 20px;
    }

    .buy-btn {
      width: 100%;
      margin-bottom: 20px;
    }

    .meta {
      list-style-type: none;
      margin: 0 0 20px 0;
      padding: 0;
    }

    .characteristic {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .name {
        flex-basis: 100px;
        font-size: 12px;
        color: #B7B7B7;
      }

      .value {
        font-size: 14px;
        color: #797979;

        a {
          font-size: 14px;
        }
      }
    }
  }
</style>
