<template>
  <div class="clothing-item-gallery"
       :class="$mq">
    <div v-if="images.length > 1">
      <div class="prev"
           ref="prev"
           v-if="images.length > 4">
        <base-icon icon="up"></base-icon>
      </div>
      <div class="swiper-container"
           ref="previewList">
        <div class="swiper-wrapper">
          <div v-for="(image, index) in images"
               :key="index"
               class="swiper-slide"
               :class="{ active: image === currentImage }"
               :style="getPreviewStyle(image)"
               @click="currentImage = image"></div>
        </div>
      </div>
      <div class="next"
           ref="next"
           v-if="images.length > 4">
        <base-icon icon="down"></base-icon>
      </div>
    </div>

    <img v-if="currentImage"
         ref="currentImage"
         :src="currentImageUrl"
         :data-zoom="currentImageUrl"
         class="current-image">

    <div class="pane-container"
         v-show="showPaneContainer"
         ref="paneContainer"></div>
  </div>
</template>

<script>
  import Drift from 'drift-zoom'
  import Swiper from 'swiper'

  export default {
    props: {
      clothingItem: {
        required: true,
        type: Object
      }
    },

    data() {
      return {
        currentImage: null,
        showPaneContainer: false
      }
    },

    mounted() {
      new Swiper (this.$refs.previewList, {
        direction: 'vertical',
        slidesPerView: 4,
        spaceBetween: 10,

        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev
        },
      })
    },

    methods: {
      getPreviewStyle(image) {
        let url = image.slices_info.find(sliceInfo => sliceInfo.title === 'small').url

        return {
          background: `url(${url}) center center / contain no-repeat`,
          backgroundOrigin: 'content-box'
        }
      }
    },

    computed: {
      images() {
        let result = []
        let mainImage = this.clothingItem.images.find(image => image.is_main)

        if (mainImage) {
          let restImages = this.clothingItem.images.filter(image => image.is_main !== true)
          result = result.concat(mainImage, restImages)
        } else {
          result = _.cloneDeep(this.clothingItem.images)
        }

        return result
      },

      currentImageUrl() {
        return this.currentImage.slices_info.find(sliceInfo => sliceInfo.title = 'origin').url
      }
    },

    watch: {
      images: {
        handler: function(images) {
          this.currentImage = images[0]

          this.$nextTick(() => {
            new Drift(this.$refs.currentImage, {
              paneContainer: this.$refs.paneContainer,
              hoverBoundingBox: true,
              onShow: () => { this.showPaneContainer = true },
              onHide: () => { this.showPaneContainer = false }
            })
          })
        },
        deep: true,
        immediate: true
      }
    }
  }
</script>

<style lang="scss">

  @import "drift-zoom/dist/drift-basic.css";

  .clothing-item-gallery {
    display: flex;
    position: relative;
    align-items: center;
    width: 624px;

    .current-image {
      display: block;
      margin: 0 auto;
      max-width: 480px;
      max-height: 360px;
    }

    .prev, .next {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper-container {
      margin: 10px 0;
      width: 80px;
      height: 360px;
    }

    .swiper-slide {
      width: 100%;
      margin-bottom: 10px;
      border: 2px solid transparent;
      border-radius: 4px;
      padding: 4px;

      &:hover, &.active {
        border: 2px solid $primary-color;
      }

      & > img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .pane-container {
      position: absolute;
      top: 0;
      left: 100%;
      width: 400px;
      height: 400px;
    }
  }
</style>
