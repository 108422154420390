<template>
  <div class="clothing-item"
       :class="$mq"
       v-if="clothingItem">
    <a href="#"
       @click.prevent="goToCatalogue"
       class="back-to-catalogue">{{ $t('shared.clothingItem.backToCatalogue') }}</a>
    <div class="content"
         :class="$mq">
      <clothing-item-gallery-desktop :clothing-item="clothingItem" v-if="$mq === 'tablet' || $mq === 'desktop'" />
      <clothing-item-gallery-mobile :clothing-item="clothingItem" v-if="$mq === 'mobile'" />
      <clothing-item-description :clothing-item="clothingItem" />
    </div>
  </div>
</template>

<script>
import clothingItemService from '@/services/queries/clothingItemQueries'
import ClothingItemDescription from './ClothingItemDescription.vue'
import ClothingItemGalleryDesktop from './ClothingItemGalleryDesktop.vue'
import ClothingItemGalleryMobile from './ClothingItemGalleryMobile.vue'

export default {
  props: {
    id: {
      required: true
    }
  },

  components: {
    ClothingItemGalleryDesktop,
    ClothingItemGalleryMobile,
    ClothingItemDescription
  },

  data() {
    return {
      clothingItem: null
    }
  },

  created() {
    clothingItemService.find(this.id).then(clothingItem => this.clothingItem = clothingItem)
  },

  methods: {
    goToCatalogue() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>


.clothing-item {
  padding: 40px 0;
  width: 1024px;
  margin: 0 auto;

  &.mobile {
    width: 100%;
    padding: 40px;
  }

  & > .content {
    display: flex;

    &.mobile {
      flex-direction: column;
    }
  }

  .back-to-catalogue {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 16px;
  }
}
</style>
