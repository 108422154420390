<template>
  <div class="clothing-item-gallery-mobile"
       :class="$mq">
    <carousel :per-page="1">
      <slide v-for="(image, key) in images"
             :key="key"
             class="slide"
             :style="getSlideStyle(image)"></slide>
    </carousel>
  </div>
</template>

<script>
  import { Carousel } from 'vue-carousel'
  import { Slide } from 'vue-carousel'

  export default {
    props: {
      clothingItem: {
        required: true,
        type: Object
      }
    },

    components: {
      Carousel,
      Slide
    },

    methods: {
      getSlideStyle(image) {
        let url = image.slices_info.find(sliceInfo => sliceInfo.title === 'middle').url

        return {
          background: `url(${url}) center center / contain no-repeat`
        }
      }
    },

    computed: {
      images() {
        let result = []
        let mainImage = this.clothingItem.images.find(image => image.is_main)

        if (mainImage) {
          let restImages = this.clothingItem.images.filter(image => image.is_main !== true)
          result = result.concat(mainImage, restImages)
        } else {
          result = _.cloneDeep(this.clothingItem.images)
        }

        return result
      }
    }
  }
</script>

<style lang="scss" scoped>

  @import "drift-zoom/dist/drift-basic.css";

  .clothing-item-gallery-mobile {
    position: relative;
    margin-bottom: 40px;

    width: 100%;
    height: 300px;
    padding: 10px;

    cursor: pointer;

    transition: border 0.2s ease-out;

    /deep/ .VueCarousel {
      width: 100%;
      height: 100%;
    }

    /deep/ .VueCarousel-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
    }

    /deep/ .VueCarousel-inner {
      width: 100%;
      height: 100%;
    }

    /deep/ .VueCarousel-dot {
      margin-top: 0 !important;
      padding: 6px !important;
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
</style>
