<template>
  <div class="clothing-item-actions">
    <div class="action"
         @mouseover="isLikeIconHovered = true"
         @mouseleave="isLikeIconHovered = false"
         @click="onLike"
         v-if="enabled('like')">
      <base-icon-like :active="liked || isLikeIconHovered"/>
      <div class="title">
        {{ $t('shared.clothingItemActions.like') }}
      </div>
    </div>

    <div class="action"
         ref="cart"
         @click="toCart"
         v-if="enabled('toCart')">
      <base-icon class="icon" icon="cart"></base-icon>
      <div class="title">
        {{ $t('shared.clothingItemActions.toCart') }}
      </div>
    </div>

    <div class="action"
         ref="studio"
         @click="toStudio"
         v-if="enabled('toStudio')">
      <base-icon icon="studio"></base-icon>
      <div class="title">
        {{ $t('shared.clothingItemActions.toStudio') }}
      </div>
    </div>

    <div class="action"
         @click="openPartner"
         v-if="enabled('buy')">
      <base-icon icon="priceTag"></base-icon>
      <div class="title">
        {{ $t('shared.clothingItemActions.buy') }}
      </div>
    </div>
  </div>
</template>

<script>
import { authenticated } from '@/services/auth'
import clothingItemService from '@/services/queries/clothingItemQueries'

export default {
  props: {
    actions: {
      required: true,
      type: Array
    },

    clothingItem: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      liked: this.clothingItem.liked,
      isLikeIconHovered: false
    }
  },

  mounted () {
    this.initAddedToCartPopup()

    if (this.enabled('toStudio')) {
      this.initAddedToStudioPopup()
    }
  },

  methods: {
    enabled (action) {
      return this.actions.indexOf(action) >= 0
    },

    onLike () {
      if (!authenticated()) {
        this.openAuthModal({ content: 'login' })
        return
      }

      if (!this.liked) {
        clothingItemService.like(this.clothingItem.id)
      } else {
        clothingItemService.removeLike(this.clothingItem.id)
      }

      this.liked = !this.liked
    },

    toCart () {
      let content = ''

      if (this.isItemInCart(this.clothingItem)) {
        content = this.$t('shared.clothingItemActions.alreadyInCart')
      } else {
        this.addToCart(this.clothingItem)
        content = this.$t('shared.clothingItemActions.addedToCart')
      }

      this.$nextTick(() => {
        this.$refs.cart._tippy.setProps({
          content
        })
        this.$refs.cart._tippy.show()
      })
    },

    toStudio () {
      let subCategory = this.getClothingSubCategoryById(this.clothingItem.clothing_subcategory_id)
      let scaleFactor = subCategory.artboard_scale_factor

      let item = {
        clothing_item_id: this.clothingItem.clothing_item_id,
        scaleFactor,
        geometry: null,
        clothingItemType: this.constants$.artboardPictureTypes.existingClothing,
        imageUrl: this.clothingItem.mainImage.middle.url
      }

      this.addToStudio(item)
      this.$nextTick(() => {
        this.$refs.studio._tippy.show()
      })
    },

    openPartner() {
      window.open(this.clothingItem.partner_side_info.url, '_blank')
    },

    // TODO: Remove, use transformed data instead
    getImageUrl(good) {
      return good.images && good.images.length > 0 ?
          good.images[0].slices_info.filter((info) => {
            return info.title === 'middle'
          })[0].url : null
    },

    initAddedToCartPopup() {
      tippy(this.$refs.cart, {
        content: this.$refs.addToCartResultMessage,
        animation: 'shift-away',
        placement: 'bottom',
        arrow: true,
        trigger: 'manual',
        appendTo: document.body,
        onShown(tooltip) {
          setTimeout(() => {
            tooltip.hide()
          }, 800)
        },
      })
    },

    initAddedToStudioPopup() {
      tippy(this.$refs.studio, {
        content: this.$t('shared.clothingItemActions.addedToStudio'),
        animation: 'shift-away',
        placement: 'bottom',
        arrow: true,
        trigger: 'manual',
        onShown(tooltip) {
          setTimeout(() => {
            tooltip.hide()
          }, 800)
        },
      })
    },

    ...mapActions('cart', {
      addToCart: 'addClothingItem'
    }),

    ...mapActions('studio', {
      addToStudio: 'addClothingItem'
    }),

    ...mapActions('system', [
      'openAuthModal'
    ]),
  },

  computed: {
    ...mapGetters('handbooks', [
      'getClothingSubCategoryById'
    ]),

    ...mapGetters('cart', [
      'isItemInCart'
    ])
  }
}
</script>

<style lang="scss">
  .clothing-item-actions {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #F4F4F4;

    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .title {
        font-size: 14px;
        color: #797979;
        line-height: 23px;
        margin-top: 8px;
      }

      & .title {
        transition: all 0.3s;
      }

      &:hover {
        .title {
          color: $primary-color;
        }
      }
    }
  }
</style>
